
$sho-red: #e20000
$sho-teal: #2b8895
$sho-right: lighten(#2b8895, 15%)
$sho-wrong: darken(#c30000, 4%)
$sho-blue: #2e546a
$sho-button: #f5001d

@mixin shadow
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 8%, rgba(0,0,0,0.86) 66%, rgba(0,0,0,1) 98%)
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 8%,rgba(0,0,0,0.86) 66%,rgba(0,0,0,1) 98%)
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 8%,rgba(0,0,0,0.86) 66%,rgba(0,0,0,1) 98%)
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 )
@mixin shadow2
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0.35) 68%, rgba(0,0,0,0.6) 98%)
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 30%,rgba(0,0,0,0.35) 68%,rgba(0,0,0,0.6) 98%)
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 30%,rgba(0,0,0,0.35) 68%,rgba(0,0,0,0.6) 98%)
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 )
@mixin shadow3
    background: linear-gradient(135deg, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0) 33%,rgba(0,0,0,0) 45%,rgba(0,0,0,0.8) 100%)
@mixin flex-center
    display: flex
    justify-content: center
    align-items: center
    text-align: center
@mixin full-center
    position: absolute
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    text-align: center
@mixin full
    position: absolute
    width: 100%
    height: 100%
@mixin full-inset
    position: absolute
    width: 100%
    height: 100%
    top: 0
    right: 0
    bottom: 0
    left: 0
//

.correct
    animation: correct 2s ease infinite
.incorrect
    animation: incorrect 2s ease infinite

@keyframes correct
    0%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1
    12.5%
        background: $sho-right
        border-color: $sho-right
        opacity: 1
    25%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1
    37.5%
        background: $sho-right
        border-color: $sho-right
        opacity: 1
    50%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1
    62.5%
        background: $sho-right
        border-color: $sho-right
        opacity: 1
    75%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1
    87.5%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1

    100%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1

@keyframes incorrect
    0%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1
    25%
        background: $sho-red
        border-color: $sho-red
        opacity: 1
    50%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1
    75%
        background: $sho-red
        border-color: $sho-red
        opacity: 1
    100%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1



































































.question-screen
    @include flex-center
    width: 100%
    height: 100%
    flex-direction: column
    justify-content: space-between
    background-color: $sho-wrong
    background-size: cover
    background-position: center
    &:before
        @include shadow3
        content: ''
        position: absolute
        width: 100%
        height: 100%
        z-index: 1
    .topbar
        display: flex
        justify-content: space-between
        align-items: center
        position: absolute
        width: 88%
        height: 15%
        z-index: 10
        .replay, .score
            display: flex
            justify-content: center
            align-items: center
            width: 18%
            height: 58px
            background-color: rgb(115 115 115 / 90%)
            border-radius: 28px
        .replay
            cursor: pointer
            img
                width: 80%
                opacity: 1
                transition: opacity 0.25s
            &:hover
                img
                    opacity: 0.8
        .score
            font-family: ff-din-web,Calibri,Arial,sans-serif
            font-weight: 600
            font-size: 3rem
            padding-top: 0.7rem
            span
                color: #3ec634
                padding-right: 0.5rem
                font-size: 3.35rem
                font-weight: 700
    *.fade
        transition: 1s
        opacity: 0
    header, section, footer
        z-index: 3
    header
        display: flex
        width: 75%
        height: 100%
        justify-content: center
        justify-self: center
        align-items: center
        p
            font-size: 3rem
            line-height: 1.25
            letter-spacing: 0.00025em
            color: white
            text-align: center
            margin-top: 17%
            margin-bottom: -2%
        .result
            display: block
            padding: 0.5rem 1rem
            width: 70%
            margin-top: 120px
            h1
                display: block
                background: rgb(0 0 0)
                padding: 1rem
                font-weight: 500
                text-transform: uppercase
            p
                margin-top: 2rem
                margin-bottom: -2rem
    section
        flex-flow: row wrap
        justify-content: center
        display: flex
        width: 90%
        padding: 2.5rem 1rem
        transition: opacity 1s
        button
            position: relative
            width: 48%
            height: 78px
            max-width: 100%
            margin: 1%
            padding: 0.5rem 1.5rem 0 1.5rem
            text-transform: none
            letter-spacing: 0.05rem
            line-height: 1.15
            font-size: 2rem
            background-color: rgb(0 0 0)
            border: 3px solid
            border-color: transparent
            span
                display: block
                color: red
        &.result
            pointer-events: none
            height: 0
    footer
        min-height: 50px
        &.result
            min-height: 150px
            width: 80%
            button
                position: relative
                width: 38%
                height: 58px
                max-width: 100%
                margin: 1%
                padding: 0.5rem 1.5rem 0 1.5rem
                letter-spacing: 0.05rem
                line-height: 1.15
                font-size: 2.5rem
                background-color: rgb(255 255 255)
                color: black
                cursor: pointer
                opacity: 1
                transition: opacity 0.25s
                &:hover
                    opacity: 0.9
                span
                    display: block
                    color: red
