
$sho-red: #e20000
$sho-teal: #2b8895
$sho-right: lighten(#2b8895, 15%)
$sho-wrong: darken(#c30000, 4%)
$sho-blue: #2e546a
$sho-button: #f5001d

@mixin shadow
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 8%, rgba(0,0,0,0.86) 66%, rgba(0,0,0,1) 98%)
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 8%,rgba(0,0,0,0.86) 66%,rgba(0,0,0,1) 98%)
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 8%,rgba(0,0,0,0.86) 66%,rgba(0,0,0,1) 98%)
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 )
@mixin shadow2
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0.35) 68%, rgba(0,0,0,0.6) 98%)
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 30%,rgba(0,0,0,0.35) 68%,rgba(0,0,0,0.6) 98%)
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 30%,rgba(0,0,0,0.35) 68%,rgba(0,0,0,0.6) 98%)
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 )
@mixin shadow3
    background: linear-gradient(135deg, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0) 33%,rgba(0,0,0,0) 45%,rgba(0,0,0,0.8) 100%)
@mixin flex-center
    display: flex
    justify-content: center
    align-items: center
    text-align: center
@mixin full-center
    position: absolute
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    text-align: center
@mixin full
    position: absolute
    width: 100%
    height: 100%
@mixin full-inset
    position: absolute
    width: 100%
    height: 100%
    top: 0
    right: 0
    bottom: 0
    left: 0
//

.correct
    animation: correct 2s ease infinite
.incorrect
    animation: incorrect 2s ease infinite

@keyframes correct
    0%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1
    12.5%
        background: $sho-right
        border-color: $sho-right
        opacity: 1
    25%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1
    37.5%
        background: $sho-right
        border-color: $sho-right
        opacity: 1
    50%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1
    62.5%
        background: $sho-right
        border-color: $sho-right
        opacity: 1
    75%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1
    87.5%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1

    100%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1

@keyframes incorrect
    0%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1
    25%
        background: $sho-red
        border-color: $sho-red
        opacity: 1
    50%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1
    75%
        background: $sho-red
        border-color: $sho-red
        opacity: 1
    100%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1

































.howto-screen
    @include flex-center
    width: 100%
    height: 100%
    flex-direction: column
    justify-content: space-between
    background-color: rgb(0 0 0 / 50%)
    &:before
        @include shadow2
        content: ''
        position: absolute
        width: 100%
        height: 100%
        z-index: 1
    header, section, footer
        z-index: 2
    header
        height: 28%
        .logo
            padding-top: 3rem
            width: 64%
    section
        color: white
        letter-spacing: 0.00025em
        font-size: 2.15rem
        line-height: 1.4
        text-align: left
        padding-right: 2rem
        .copy
            display: flex
            flex-flow: column
            justify-content: center
            align-items: center
            text-align: center
            width: 78%
            h1
                font-size: 3.75rem
                padding-bottom: 1rem
                text-transform: uppercase
            p
                text-shadow: 0 0 2px rgb(0 0 0)
                margin-bottom: 1.5rem
            img
                margin-bottom: 0.8rem
                max-height: 88px
            button
                margin-top: 0.8rem
                margin-bottom: 0.2rem
    footer
        flex-direction: column
        justify-self: flex-end
        padding: 2rem
        p
            width: 100%
            font-size: 2.15rem
            font-weight: 500
            color: white
            line-height: 1.5
            border: 3px solid $sho-teal
            background: rgb(43 136 149 / 50%)
            padding: 1rem 0
    > *
        @include flex-center
        width: 100%
