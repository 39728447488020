// imports
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap')
@import url('https://use.typekit.net/wkk8qrx.css')
@import '~bootstrap/scss/bootstrap'
@import '~tailwindcss/base'
@import '~tailwindcss/components'
@import '~tailwindcss/utilities'

@font-face
    font-family: 'Gotham Ultra'
    font-style: italic
    font-weight: 500
    src: url('../fonts/Gotham-UltraItalic.woff') format('woff')

@font-face
    font-family: 'Atyp'
    font-weight: 100 300
    font-style: normal
    font-display: swap
    src: url("../fonts/ShowtimeAtypText-Light.eot")
    src: local("ShowtimeAtypText-Light"), url("../fonts/ShowtimeAtypText-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/ShowtimeAtypText-Light.woff2") format("woff2"), url("../fonts/ShowtimeAtypText-Light.woff") format("woff"), url("../fonts/ShowtimeAtypText-Light.ttf") format("truetype"), url("../fonts/ShowtimeAtypText-Light.svg#ShowtimeAtypText-Light") format("svg")

@font-face
    font-family: 'Atyp'
    font-weight: 400
    font-style: normal
    font-display: swap
    src: url("../fonts/ShowtimeAtypText-Regular.eot")
    src: local("ShowtimeAtypText-Regular"), url("../fonts/ShowtimeAtypText-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/ShowtimeAtypText-Regular.woff2") format("woff2"), url("../fonts/ShowtimeAtypText-Regular.woff") format("woff"), url("../fonts/ShowtimeAtypText-Regular.ttf") format("truetype"), url("../fonts/ShowtimeAtypText-Regular.svg#ShowtimeAtypText-Regular") format("svg")

@font-face
    font-family: 'Atyp'
    font-weight: 600 900
    font-style: normal
    font-display: swap
    src: url("../fonts/ShowtimeAtypText-Semibold.eot")
    src: local("ShowtimeAtypText-Semibold"), url("../fonts/ShowtimeAtypText-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/ShowtimeAtypText-Semibold.woff2") format("woff2"), url("../fonts/ShowtimeAtypText-Semibold.woff") format("woff"), url("../fonts/ShowtimeAtypText-Semibold.ttf") format("truetype"), url("../fonts/ShowtimeAtypText-Semibold.svg#ShowtimeAtypText-Semibold") format("svg")

@font-face
    font-family: 'AntiqueLegacy'
    font-weight: 200 400
    font-style: normal
    font-display: swap
    src: url("../fonts/AntiqueLegacy-Light.eot")
    src: local("AntiqueLegacy-Light"), url("../fonts/AntiqueLegacy-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/AntiqueLegacy-Light.woff2") format("woff2"), url("../fonts/AntiqueLegacy-Light.woff") format("woff"), url("../fonts/AntiqueLegacy-Light.ttf") format("truetype"), url("../fonts/AntiqueLegacy-Light.svg#AntiqueLegacy-Light") format("svg")

@font-face
    font-family: 'AntiqueLegacy'
    font-weight: 200
    font-style: normal
    font-display: swap
    src: url("../fonts/AntiqueLegacy-Light.eot")
    src: local("AntiqueLegacy-Light"), url("../fonts/AntiqueLegacy-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/AntiqueLegacy-Light.woff2") format("woff2"), url("../fonts/AntiqueLegacy-Light.woff") format("woff"), url("../fonts/AntiqueLegacy-Light.ttf") format("truetype"), url("../fonts/AntiqueLegacy-Light.svg#AntiqueLegacy-Light") format("svg")

@font-face
    font-family: 'AntiqueLegacy'
    font-weight: 400
    font-style: normal
    font-display: swap
    src: url("../fonts/AntiqueLegacy-Regular.eot")
    src: local("AntiqueLegacy-Regular"), url("../fonts/AntiqueLegacy-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/AntiqueLegacy-Regular.woff2") format("woff2"), url("../fonts/AntiqueLegacy-Regular.woff") format("woff"), url("../fonts/AntiqueLegacy-Regular.ttf") format("truetype"), url("../fonts/AntiqueLegacy-Regular.svg#AntiqueLegacy-Light") format("svg")

@font-face
    font-family: 'AntiqueLegacy'
    font-weight: 500
    font-style: normal
    font-display: swap
    src: url("../fonts/AntiqueLegacy-Medium.eot")
    src: local("AntiqueLegacy-Medium"), url("../fonts/AntiqueLegacy-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/AntiqueLegacy-Medium.woff2") format("woff2"), url("../fonts/AntiqueLegacy-Medium.woff") format("woff"), url("../fonts/AntiqueLegacy-Medium.ttf") format("truetype"), url("../fonts/AntiqueLegacy-Medium.svg#AntiqueLegacy-Medium") format("svg")

@font-face
    font-family: 'AntiqueLegacy'
    font-weight: 600
    font-style: normal
    font-display: swap
    src: url("../fonts/AntiqueLegacy-Semibold.eot")
    src: local("AntiqueLegacy-Semibold"), url("../fonts/AntiqueLegacy-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/AntiqueLegacy-Semibold.woff2") format("woff2"), url("../fonts/AntiqueLegacy-Semibold.woff") format("woff"), url("../fonts/AntiqueLegacy-Semibold.ttf") format("truetype"), url("../fonts/AntiqueLegacy-Semibold.svg#AntiqueLegacy-Semibold") format("svg")

@font-face
    font-family: 'AntiqueLegacy'
    font-weight: 700 900
    font-style: normal
    font-display: swap
    src: url("../fonts/AntiqueLegacy-Bold.eot")
    src: local("AntiqueLegacy-Bold"), url("../fonts/AntiqueLegacy-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/AntiqueLegacy-Bold.woff2") format("woff2"), url("../fonts/AntiqueLegacy-Bold.woff") format("woff"), url("../fonts/AntiqueLegacy-Bold.ttf") format("truetype"), url("../fonts/AntiqueLegacy-Bold.svg#AntiqueLegacy-Bold") format("svg")

// basic
#app
    -webkit-user-select: none
    -moz-user-select: none
    -o-user-select: none
    user-select: none
    height: 100%
    min-height: 600px
    font-family: 'AntiqueLegacy'
html
    background: #1f1f1f
body
    font-size: 200% // idk ?
    width: 100%
    overflow-x: hidden
img
    -webkit-user-drag: none
    -moz-user-drag: none
    -o-user-drag: none
    user-drag: none
.question
    font-size: 4rem !important
sup
    font-size: 60%

button
    width: 44%
    max-width: 285px
    margin: 1rem 2rem 0 1rem
    padding: 1.25rem 2rem 0.65rem 2rem
    color: white
    font-size: 2.5rem
    font-family: 'Hind', sans-serif
    font-weight: 600
    text-transform: uppercase
    letter-spacing: 0.15rem
    background: #f5001d
.title p strong
    font-weight: 700
    color: #f5001d
// animations
.fade-enter-active, .fade-leave-active
    position: absolute
    transition: opacity 1s

.fade-enter, .fade-leave-to
    opacity: 0

// helpers
.freeze
    pointer-events: none !important
    cursor: not-allowed !important

.x
    display: none

svg
    height: 100%

strong
    font-weight: 600

#logo
    path
        fill: white
    .circle
        fill: #e20000
// screen
@media only screen and (min-device-width: 320px) and (max-device-width: 920px) and (orientation: portrait)
    .main-screen, #app
        min-height: 900px
//

@media (min-width: 640px)
    .container
        max-width: 100%

@media (min-width: 768px)
    .container
        max-width: 100%

@media (min-width: 1024px)
    .container
        max-width: 995px

@media (min-width: 1280px)
    .container
        max-width: 1095px
