
$sho-red: #e20000
$sho-teal: #2b8895
$sho-right: lighten(#2b8895, 15%)
$sho-wrong: darken(#c30000, 4%)
$sho-blue: #2e546a
$sho-button: #f5001d

@mixin shadow
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 8%, rgba(0,0,0,0.86) 66%, rgba(0,0,0,1) 98%)
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 8%,rgba(0,0,0,0.86) 66%,rgba(0,0,0,1) 98%)
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 8%,rgba(0,0,0,0.86) 66%,rgba(0,0,0,1) 98%)
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 )
@mixin shadow2
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0.35) 68%, rgba(0,0,0,0.6) 98%)
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 30%,rgba(0,0,0,0.35) 68%,rgba(0,0,0,0.6) 98%)
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 30%,rgba(0,0,0,0.35) 68%,rgba(0,0,0,0.6) 98%)
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 )
@mixin shadow3
    background: linear-gradient(135deg, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0) 33%,rgba(0,0,0,0) 45%,rgba(0,0,0,0.8) 100%)
@mixin flex-center
    display: flex
    justify-content: center
    align-items: center
    text-align: center
@mixin full-center
    position: absolute
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    text-align: center
@mixin full
    position: absolute
    width: 100%
    height: 100%
@mixin full-inset
    position: absolute
    width: 100%
    height: 100%
    top: 0
    right: 0
    bottom: 0
    left: 0
//

.correct
    animation: correct 2s ease infinite
.incorrect
    animation: incorrect 2s ease infinite

@keyframes correct
    0%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1
    12.5%
        background: $sho-right
        border-color: $sho-right
        opacity: 1
    25%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1
    37.5%
        background: $sho-right
        border-color: $sho-right
        opacity: 1
    50%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1
    62.5%
        background: $sho-right
        border-color: $sho-right
        opacity: 1
    75%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1
    87.5%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1

    100%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1

@keyframes incorrect
    0%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1
    25%
        background: $sho-red
        border-color: $sho-red
        opacity: 1
    50%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1
    75%
        background: $sho-red
        border-color: $sho-red
        opacity: 1
    100%
        background: rgb(0 0 0)
        border-color: transparent
        opacity: 1












































.video-screen
    @include flex-center
    flex-flow: column
    justify-content: stretch
    width: 100%
    height: 100%
    background: rgb(0 0 0 / 90%)
    z-index: 1
    section, footer
        width: 100%
        height: 100%
    section
        .skip
            position: absolute
            top: -6px
            right: 8px
            font-size: 3.5rem
            letter-spacing: 0.05rem
            text-transform: uppercase
            cursor: pointer
            z-index: 10
            opacity: 0.5
            transition: opacity 0.25s
            &:hover
                opacity: 0.75
        .video
            position: absolute
            width: 100%
            height: 100%
            top: -34px
            // height: 0
            // padding-bottom: 56.25%
            iframe
                order: 0
                height: 100%
                left: 0
                position: absolute
                top: 0
                user-select: none
                width: 100%
    footer
        display: flex
        align-items: flex-end
        .skip
            position: absolute
            bottom: 30px
            left: 15px
            font-size: 1.5rem
            letter-spacing: 0.05rem
            text-transform: uppercase
            text-decoration: underline
            text-underline-offset: 0.25rem
            cursor: pointer
            &:hover
                opacity: 0.85
                transition: opacity 0.25s
        .vidProgress
            height: 10px
            width: 0%
            margin: 12px
            background: $sho-teal
            // transition: width 0.25s
